import React from "react";
import Carousel from "react-multi-carousel";
import { Container, Row, Col} from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";

const Testimonials = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    return (
        <>
            <section className="mainSection testimonialsSection bg-light-green4">
                <Container fluid>
                    <Row>
                        <Col>
                            <h2 className="h2 h2-main color-white text-center padding-top-50 padding-bottom-50">Testimonios</h2>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col>
                                <Carousel
                                    swipeable={false}
                                    draggable={false}
                                    showDots={false}
                                    responsive={responsive}
                                    ssr={false} // means to render carousel on server-side.
                                    infinite={false}
                                    autoPlay={false}    
                                    autoPlaySpeed={1000}
                                    keyBoardControl={false}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px "
                                >
                                    <div className="testimonialCard bg-light-green2">
                                        <div className="testimonialImange">
                                            <img alt="judith-group" src="https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/testimonials%2Ftours-grupo-testimonio.jpg?alt=media&token=16d7a83e-d062-4b4f-b387-551dda5bb236" />
                                        </div>
                                        <h3 className="">grupo de 6 personas</h3>
                                        <h4 className="color-green3">Tour 13 días en septiembre de 2024</h4>
                                        <h5>JUDITH BC BARCELONA</h5>
                                        <p>El guía nos llevó a lugares secretos, convivimos con la gente local, visitamos a sus lugares de trabajo, su forma de vivir incluido su comida. Visitamos tres parques naturales, hicimos un trekking por las tierras altas. Estuvimos en hoteles con piscina. Un 10 al guía Vajira y todo su equipo</p>
                                        <p className="stars">·····</p>
                                        <h5> FANTÁSTICO VIAJE</h5>
                                    </div>


                                    <div className="testimonialCard bg-light-green2">
                                        <div className="testimonialImange">
                                            <img alt="ÁNGEL D.M MADRID" src="https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/testimonials%2Ftours-grupo-en-sri-lanka1.jpg?alt=media&token=2d3a0596-76ef-4b18-bf30-0d079d11e75b" />
                                        </div>
                                        <h3 className="">grupo de 4 personas</h3>
                                        <h4 className="color-green3">Tour 11 días en agosto de 2024</h4>
                                        <h5>ÁNGEL D.M MADRID</h5>
                                        <p>Una mezcla de visitas a la naturaleza, aventuras y lugares relajantes. El tour fue totalmente adaptado a nuestras preferencias y cumplió con todo. La puntualidad, la amabilidad y, sobre todo, el cuidado constante que Vajira y su equipo demuestran en cada momento hicieron de este viaje algo increíble</p>
                                        <p className="stars">·····</p>
                                        <h5>¡ 100% RECOMENDADO !</h5>
                                    </div>

                                    <div className="testimonialCard bg-light-green2">
                                        <div className="testimonialImange">
                                            <img alt="ÁNGEL D.M MADRID" src="https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/testimonials%2Ftours-grupo-en-sri-lanka1.jpg?alt=media&token=2d3a0596-76ef-4b18-bf30-0d079d11e75b" />
                                        </div>
                                        <h3 className="">grupo de 6 personas</h3>
                                        <h4 className="color-green3">Tour 7 días en agosto de 2024</h4>
                                        <h5>SUSANA A.M VALENCIA</h5>
                                        <p>Vajira es un guía excepcional por su conocimiento profundo del país, transmitiéndonos su historia, cultura, gastronomía y tradiciones. Cada día ha sido una aventura diferente. Todo ello aderezado con su fantástico español con sus refranes!!! Nos ha hecho disfrutar al máximo de nuestra estancia.  Mil gracias Vajira!!!</p>
                                        <p className="stars">·····</p>
                                        <h5>MARAVILLOSO VIAJE Y TRATO INSUPERABLE</h5>
                                    </div>

                                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
        </>
    );
};

export default Testimonials;